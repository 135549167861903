import React from 'react';
import { useEffect } from "react";
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import './Home.css';
import {FaFootballBall} from "react-icons/fa";
import {MdGolfCourse} from "react-icons/md";
import google from '../images/Google_Play.png'
import apple from '../images/App_Store.png'
import { useState } from 'react';
import two from '../images/Home_2.png'
import three from '../images/Home_3.svg'
import four from '../images/Home_4.svg'
import five from '../images/Home_5.png'
import six from '../images/Home_6.png'
import seven from '../images/Home_7.png'
import eight from '../images/Home_8.png'
import nine from '../images/Home_9.png'
import ten from '../images/Home_10.png'
import eleven from '../images/Home_11.png'
import OpenPopUp2 from './OpenPopUp2';

const customStyles = {
    content: {
      padding:0,
      margin:0,
      width:600,
      height:350,
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius:40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:"#272727"
    },
  };

class Home2 extends React.Component {
    state = {
        navBackground1: "#FFFFFF",
        navBackground2: "#FFFFFF",
        navBackground3: "#FFFFFF",
        navBackground4: "#FFFFFF"
      };
    
    componentDidMount() {
        document.addEventListener("scroll", () => {
          const backgroundcolor1 = (window.scrollY < 300 || window.scrollY > 925) ? "#FFFFFF" : "#19E094";
          const backgroundcolor2 = (window.scrollY < 700 || window.scrollY > 1350) ? "#FFFFFF" : "#19E094";
          const backgroundcolor3 = (window.scrollY < 1050 || window.scrollY > 1750) ? "#FFFFFF" : "#19E094";
          const backgroundcolor4 = (window.scrollY < 2100 || window.scrollY > 5000) ? "#FFFFFF" : "#19E094";

          this.setState({ navBackground1: backgroundcolor1 });
          this.setState({ navBackground2: backgroundcolor2 });
          this.setState({ navBackground3: backgroundcolor3 });
          this.setState({ navBackground4: backgroundcolor4 });

          
        });
        setTimeout(() => window.scrollTo(0,0));
      }
    render() {
    return (
        
        <div class="Home20">
            <ul class="HomeText2">
                <li>
                    <h1 class="Header2Home2020">Built by Users, for Users</h1>
                </li>
                <br />
                <li><p class="PHome20">DFS contests that <span class="greenText20">eliminate the need for perfect parlays</span>
                . Start playing against your friends today or <span class="greenText20">get matched</span> with opponents in peer-to-peer contests.</p></li>
                {/* <li><p class="PHome20">Head to head DFS <span class="greenText20">parlay contests</span>. Get matched with users with the same skill level and maximize your rewards with <span class="greenText20">parlays that never miss</span>!</p></li> */}
                <br />
                <li><a href="https://www.parlaye.com/#how-it-works" className="howItWorksButton2">How it works?</a></li>
                <li class="JoinWaitlist"><OpenPopUp2 /></li>
            </ul>
            <a name="how-it-works"><br /><h2 class="Header2Home20">How it Works</h2></a>
            <br /><br />
            <h2 class="Header2Home220">1. Find Players</h2>
            <p class="PHome220">Pick at least 2 of your favorite player props. Simply pick over & under. Mix and match multiple sports.</p>
            <img src={two} alt="" class="home120"/>
            <br /><br />
            <h2 class="Header2Home220">2. Get Matched with Users</h2>
            <p class="PHome220">As the first curated daily fantasy sports app, we handpick your opponents to maximize entertainment.</p>
            <img src={three} alt="" class="home121"/>
            <br /><br />
            <h2 class="Header2Home220">3. Track Parlays in Real Time</h2>
            <p class="PHome220">Say goodbye to perfection in parlays. Just because you miss a pick, doesn’t mean you can’t beat your opponent. <br /><br />You invested in this parlay, it should entertain you longer than the time it takes for a single pick to fail.</p>
            <img src={four} alt="" class="home120"/>
            <br />
            <h2 class="Header2Home20">Follow Us</h2>
            <br />
            {/* <p class="PHome220">We are building Parlaye for you! Join us on socials and have the opportunity to redeem bet credits once we a live. </p> */}
            <a href="https://twitter.com/ParlayeOfficial"><img src={five} alt="" class="home420"/></a>
            <a href="https://www.tiktok.com/@parlaye_official"><img src={six} alt="" class="home420"/></a>
            <a href="https://discord.gg/gHqZVnR8CB"><img src={seven} alt="" class="home420"/></a>
            <br />
            <a href="https://www.instagram.com/parlayeofficial/"><img src={eight} alt="" class="home420"/></a>
            <a href="https://www.linkedin.com/company/parlaye-inc/about/?viewAsMember=true"><img src={nine} alt="" class="home420"/></a>
            <br /><br />
            <h2 class="Header2Home20">Available Now</h2>
            <br />
            <p class="PHome220">Download the Parlaye App today on the App Store or Google Play Store!</p>
            <a href="https://play.google.com/store/apps/details?id=com.parlaye.parlaye"><img src={google} alt="" class="googleImg2"/></a>
            <a href="https://apps.apple.com/us/app/parlaye/id1584562998"><img src={apple} alt="" class="appleImg"/></a>
            {/* <img src={eleven} alt="" class="home120"/> */}
            <br /><br /><br />
        </div>


    )
    }
}

export default Home2;